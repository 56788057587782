<template>
  <div class="bg-white rounded-10 text-left">
    <div class="flex">
      <div class="w-full">
        <div class="p-20 pt-10 pb-0">
          <div class="text-c14 text-bd">
            Fournisseur
          </div>

          <div class="mt-6 flex">
            <div class="flex w-1/2 items-center">
              <icon
                :data="icons.company"
                height="20"
                width="20"
                class="cursor-pointer"
                original
              />
              <div class="ml-4 w-3/5 text-c20 font-c6">
                CONNANT
              </div>
            </div>

            <div class="w-1/2 flex">
              <div class="w-1/2">
                <div class="text-83">
                  Date de facturation fournisseur:
                </div>
                <datepicker placeholder="Date" />
              </div>

              <div class="w-1/2 ml-6">
                <div class="text-83">
                  Référence :
                </div>
                <inputo />
              </div>
            </div>
          </div>
        </div>

        <div class="h-1 bg-d w-full mt-10 mb-10" />

        <div class="p-20 pt-0">
          <articleListe
            :all-article="sendArticle"
            @add="retour"
          />
        </div>
      </div>
    </div> 

    <div v-if="sendArticle.length > 0">
      <div class="h-1 bg-d w-full mt-10 mb-6" />

      <div class="flex pl-6 pb-6">
        <div>
          Service: <span class="text-60"> Compatabilité</span>
        </div>

        <div class="ml-4">
          Auteur: <span class="text-60">Samuel H</span>  
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import company from '../../../assets/icons/company.svg'
import datepicker from '../../helper/form/datePicker.vue'
import inputo from '../../helper/form/input.vue'
import articleListe from './articleListe.vue'

export default {
  name: "Index",
  components: {
    datepicker,
    inputo,
    articleListe
  },
  props: {
    allArticle: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        company
      }
      // totalAmount: 0
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendArticle: function () {
      return this.allArticle
    }
  },

  created () {
    console.log('k')
    console.log(this.allArticle)
  },

  methods: {

    retour(){
      this.$emit('add', false)
    }

    // sender () {
    //   if (this.allBillet.length > 0) {
    //     let total = 0
    //     for (let item in this.allBillet) {
    //       total = total + (this.allBillet[item].count * this.allBillet[item].amount)
    //     }
    //     this.totalAmount = total
    //   } else {
    //     this.totalAmount = 0
    //   }
    //   this.$emit('info', this.allBillet)
    // }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
</style>
