<template>
  <div class="w-full bg-white rounded-10 p-10 pt-20 pb-32 text-left">
    <div class="flex justify-center">
      <div class="w-3/5">
        <div class="text-c12 mt-4">
          Type d’article
        </div>

        <multiselect
          background="#F4F5F7"
          border="1px solid #DCDCE4"
          class="mt-2"
          :option="optionMoyen"
          @info="retourMoyen"
        />
      </div>
    </div>
  </div>
</template>

<script>
import multiselect from '../../helper/form/multiselect'

export default {
  name: "Index",
  components: {
    multiselect,
  },

  props: {
  },

  data () {
    return {
      response: {},
      optionMoyen: ['Monture', 'Accessoire', 'Verres de stock', 'Matériel technique'],
    }
  },

  created () {
  },

  methods: {
    retourMoyen (a) {
      this.$emit('info', a)
    },
  },
}
</script>

<style lang="scss" scoped>
  .libel{
    background-color: #FFF9EE;
  }
</style>
