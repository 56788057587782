<template>
  <div class="w-full">
    <multiselect
      :option="optionDevise"
      :value-t="valueDevise"
      @info="retourDevise"
      class="mt-2"
    />
  </div>
</template>

<script>
import axios from 'axios'
import multiselect from './multiselect'

export default {
  name: "Index",

  components: {
    multiselect,
  },

  props: {
  },

  data () {
    return {
      response: {},
      charge: false,
      valueDevise: 'USD',
      optionDevise: ['USD','EUR', 'XOF', 'CNY', 'RUB', 'CAD', 'BRL', 'XAF', 'KYD', 'BOV'],
      devise: [],
    }
  },

  created () {
    // this.getDevise()
  },

  methods: {

    retourDevise(answer){
     this.$emit('info', answer)
    },

    // getDevise(){
    //   axios.get('https://free.currconv.com/api/v7/currencies?apiKey=b23c673e60ff43958846')
    //       .then(response => {
    //         const data = response.data.results
    //         let tab = []
    //         let detail = []
    //         for (const property in data) {
    //           tab.push(data[property])
    //           detail.push(data[property].id)
    //         }
    //         this.devise = tab
    //         this.optionDevise = detail
    //       })
    //       .catch(error => {
    //         this.charge = false
    //         console.log(error)
    //       })
    // },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  font-family: $font-default;
  height: 40px;
  border-radius: 5px
}
.lab{
  color: #32324D;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
  background-color: #D99E82;
  width: 10%;
}
.bout:hover{
  border: 0.5px solid gray;
 // background-color: $hover-color;
}
.diviser {
  height: 1px;
  background-color: #EAEAEF;
}
</style>
