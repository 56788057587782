<template>
  <div class="bg-white rounded-10 p-20 pt-20 pb-32 text-leftall">
    <confirmation
      :activation="activConfirm"
      v-if="activConfirm"
      @oga="backConfirm"
      description="Confirmer la création d’un nouvel article ?"
    />

    <success
      :activation="activSuccess"
      v-if="activSuccess"
      @oga="backSuccess"
      message="Article enregistré avec succès"
    />

    <div
      class="flex justify-center w-full text-left"
      v-if="factureType !== 'Accessoire'"
    >
      <div class="w-3/5">
        <div class="text-c12 mt-6">
          Marque
        </div>

        <multiselect
          background="#fff"
          :value-t="optionMarque[0]"
          border="1px solid #DCDCE4"
          class="mt-2"
          :option="optionMarque"
          @info="retourMarque"
        />

        <div
          class="text-c12 mt-6"
          v-if="factureType === 'Monture'"
        >
          Référence :
        </div>

        <div
          class="text-c12 mt-6"
          v-if="factureType !== 'Monture'"
        >
          Libellé :
        </div>

        <inputo
          border="1px solid #DCDCE4"
          class="mt-2"
          placeholder="--"
          @info="reference"
        />

        <div class="text-c12 mt-6">
          Quantité :
        </div>

        <inputo
          border="1px solid #DCDCE4"
          class="mt-2"
          :value-t="0"
          type="number"
          @info="qte"
        />

        <div class="text-c12 mt-6">
          Prix unitaire :
        </div>

        <div class="flex">
          <div class="w-1/4">
            <devise @info="backDevise" />
          </div>

          <div class="ml-4">
            <inputo
              border="1px solid #DCDCE4"
              class="mt-2"
              :value-t="0"
              type="number"
              @info="price"
            />
          </div>
        </div>
      </div>
    </div>

    <accessory
      v-if="factureType === 'Accessoire'"
      @info="backAccessory"
    />

    <div class="mt-10 h-1 bg-d w-full" />

    <div class="flex justify-center text-left mt-10">
      <div class="flex justify-end w-3/5">
        <bouton
          height="44.44px"
          label="Retour"
          background="#EAEAEF"
          color="#606060"
          class="w-1/3"
          radius="5px"
          size="16px"
          weight="600"
        />

        <bouton
          height="44.44px"
          label="Ajouter l’article"
          weight="600"
          size="16px"
          radius="5px"
          class="w-2/5 ml-4"
          @oga="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import multiselect from '../../helper/form/multiselect'
import inputo from '../../helper/form/input'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import devise from '../../helper/form/devise.vue'
import bouton from '../../helper/add/button.vue'
import confirmation from '../../popup/confirmation.vue'
import success from '../../popup/success.vue'
import accessory from './accessoryForm.vue'


export default {
  name: "Index",
  components: {
    multiselect,
    inputo,
    devise,
    bouton,
    confirmation,
    success,
    accessory
  },
  props: {
    factureType: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      response: {},
      optionMarque: ['RAYBAN', 'LV', 'VS', 'DC'],
      activConfirm: false,
      activSuccess: false
    }
  },

  created () {
  },

  methods: {
    retourMarque (a) {
      this.response.marque = a
    },

    backConfirm (a) {
      if (a) {
        this.activSuccess = true
      }
      this.activConfirm = false
    },

    backSuccess (a) {
      this.$emit('info', this.response)
    },

    reference(t){
      this.response.reference = t
    },

    backDevise (p) {
      this.response.devise = p
    },

    price (p) {
      this.response.price = p
    },

    qte (q) {
      this.response.qte = q
    },

    save () {
      this.activConfirm = true
    },

    backAccessory (a) {
      this.response = a
    }

  },
}
</script>

<style lang="scss" scoped>
</style>
