<template>
  <div class="w-full text-left">
    <div>
      <div class="flex justify-center">
        <div class="mt-10 w-3/5">
          <div class="text-c12">
            Type d’accessoire
          </div>

          <multiselect
            background="#F4F5F7"
            border="1px solid #DCDCE4"
            class="mt-2"
            :option="optionAccessories"
            @info="retourAccessoire"
          />
      

          <div class="flex">
            <div class="w-1/2">
              <div class="text-c12 mt-4">
                Marque
              </div>

              <multiselect
                background="#F4F5F7"
                border="1px solid #DCDCE4"
                class="mt-2"
                :option="optionMarque"
                @info="retourMarque"
              />
            </div>

            <div class="w-1/2 ml-4">
              <div class="text-c12 mt-4">
                Fournisseur
              </div>

              <multiselect
                background="#F4F5F7"
                border="1px solid #DCDCE4"
                class="mt-2"
                :option="optionFournisseur"
                @info="retourFournisseur"
              />
            </div>
          </div>

          <div class="text-c12 mt-4">
            Fournisseur
          </div>

          <div class="h-50 libel mt-2 rounded-5 flex items-center pl-4">
            <div class="w-3/4 text-60">
              {{ productName }}
            </div>

            <div class="flex items-center w-1/4">
              <div class="text-oho-green">
                Produit existant
              </div>

              <icon
                :data="icons.valider"
                height="17"
                width="17"
                class="cursor-pointer ml-2"
                original
                @click="retour"
              />
            </div>
          </div>

          <div class="text-c12 mt-4">
            Quantité
          </div>

          <inputo
            class="mt-2"
            type="number"
            placeholder="0"
            @info="backQuantite"
          />

          <div class="w-full flex">
            <div class="w-1/2 mr-4">
              <div class="text-c12 mt-4">
                Prix unitaire
              </div>

              <div class="flex items-center">
                <div class="w-1/3">
                  <devise
                    height="40px"
                    @info="backDevise"
                  />
                </div>

                <div class="w-2/3 ml-4 mt-2">
                  <inputo
                    type="number"
                    placeholder="0.00"
                    height="40px"
                    @info="backPrice"
                  />
                </div>
              </div>
            </div> 

            <div class="w-1/2">
              <div class="text-c12 mt-4">
                Prix CFA
              </div>

              <div class="">
                <inputo
                  class="w-full mt-2"
                  type="number"
                  placeholder="0.00"
                  @info="backPriceCfa"
                />
              </div>
            </div> 
          </div>

          <div class="mt-10 flex items-center">
            <div>Coût de revient :</div>
  
            <div class="h-40 flex items-center pl-3 pr-3 ml-5 bg-f5 w-1/3">
              <div>{{ Math.floor((value * (response.prixUnit * 2) * 4) + 5500) }}</div>
            </div>
  
            <div class="flex items-center ml-6">
              <radios @info="retourTva" />
              <div class="ml-4 text-60 text-c12">
                Assujetie à la TVA (18%)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import multiselect from '../../helper/form/multiselect'
import inputo from '../../helper/form/input'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import valider from '../../../assets/icons/validd.svg'
import devise from '../../helper/form/devise.vue'
import radios from '../../helper/form/radio'

export default {
  name: "Index",
  components: {
    multiselect,
    inputo,
    devise,
    radios,
  },

  props: {
  },

  data () {
    return {
      icons: {
        valider
      },
      productName: 'MONTURE RAYBAN ASKD994',
      optionCompte: [],
      response: {},
      allData: [],
      wallet: null,
      optionMoyen: ['Monture', 'Accessoire', 'Verres de stock', 'Matériel technique'],
      optionAccessories: ['Etuis', 'Essui', 'Eau de correction', '...'],
      optionFournisseur: ['Etuis', 'Essui', 'Eau de correction', '...'],
      optionMarque: ['Etuis', 'Essui', 'Eau de correction', '...'],
      valueType: null
    }
  },

  created () {
  },

  methods: {
    retourMoyen (a) {
      if(a === this.optionMoyen[0]){
        this.$emit('info', a)
      } else {
        console.log(a)
      }
      this.valueType = a 
    },

    retourAccessoire (e) {
      this.response.accessoire = e
    },

    retourMarque (m) {
      this.response.marque = m
    },

    retourFournisseur (m) {
      this.response.supplier = m
    },

    backQuantite (q) {
      this.response.qte = q
      this.response.reference = this.productName
    },

    backDevise (p) {
      this.response.devise = p
    },

    backPrice (p) {
      this.response.price = p
    },

    backPriceCfa (p) {
      this.response.priceCfa = p
      this.$emit('info', this.response)
    }
  },
}
</script>

<style lang="scss" scoped>
  .libel{
    background-color: #FFF9EE;
  }
</style>
