<template>
  <div class="p-10 pr-16 all">
    <confirmation
      :activation="activConfirmation"
      v-if="activConfirmation"
      description="Confirmer l’enregistrement de le facture interne ?"
      @oga="backConfirm"
    />
    <popupCharge
      :activation="charge"
      v-if="charge"
    />
    <success
      v-if="activeSuccess"
      :pdf="pdf"
      :message="message"
      :activation="activeSuccess"
      @oga="retourSuccess"
    />
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-2/3 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Créer une facture
          </div>
        </div>

        <div class="w-1/3 flex">
          <bouton
            v-if=" position === 2 && allArticle.length > 0"
            label="Retour"
            height="54.4px"
            class="w-1/2"
            background="#DDDDDD"
            color="#606060"
            @click="retour"
          />
          <bouton
            label="Enregistrer"
            v-if="position === 2 && allArticle.length > 0"
            height="54.4px"
            class="w-1/2 ml-4"
            @info="continuer"
          />
        </div>
      </div>

      <div class="mt-10 pl-4">
        <factureType
          v-if="position === 1"
          @info="retourfactureType"
        />

        <div v-if="position === 2">
          <articles
            :all-article="sendArticle"
            @add="retourArticle"
          />
        </div>

        <div v-if="position === 3">
          <addArticle
            @info="backArticle"
            :facture-type="factureType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import articles from '../../component/cdevs/nouvelleFacture/article.vue'
import factureType from '../../component/cdevs/nouvelleFacture/factureType'
import success from '../../component/popup/success'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import addArticle from '../../component/cdevs/nouvelleFacture/addArticle.vue'
import confirmation from '../../component/popup/confirmation.vue'

export default {
  name: "Index",

  components: {
    bouton,
    articles,
    factureType,
    success,
    popupCharge,
    addArticle,
    confirmation
  },

  data () {
    return {
      icons: {
        back,
      },
      position: 1,
      activeRecap: false,
      activeSuccess: false,
      message: 'Retrait bancaire enregistré avec succès',
      allArticle: [],
      cheques: null,
      factureType: null,
      totalRemise: 0,
      recap: null,
      charge: false,
      pdf: null,
      activConfirmation: false,
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendArticle: function () {
      return this.allArticle
    }
  },

  created () {
  },

  methods: {
    selected (index) {
      this.position = index
    },

    backConfirm (a) {
      if(a) {
        this.message = 'Facture interne enregistré avec succès'
        this.activeSuccess = true
      }
      this.activConfirmation = false
    },

    retourfactureType (answer) {
      this.factureType = answer
      this.position++
    },

    retourArticle (answer) {
      this.position = 3
    },

    backArticle(a) {
      this.allArticle.push(a)
      console.log(this.allArticle)
      this.position--
    },


    continuer () {
      this.activConfirmation = true
    },

    save() {
      if (this.recap.file) {
        this.saveFile(this.recap.file)
      } else {
        alert('Aucune preuve')
        this.position = 3
      }
    },

    //Ici j'enregistre les fichiers et je récupère des url
    saveFile (file) {
      this.charge = true
      let data = new FormData()
      data.append('files', file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            this.finaly(response[0].url)
          })
          .catch(error => {
            console.log(error)
          })
    },

    finaly (url) {
      const obj = {
        
      }

      http.post(apiroutes.baseURL + apiroutes.retrait, obj)
          .then(response => {
            console.log(response)
            this.pdf = response.pdf
            this.activeSuccess = true
          })
          .catch(error => {
            console.log(error)
          })
    },

    retour () {
      if(this.position === 0) {
        window.history.back()
      } else {
        this.position--
      } 
    },

    retourSuccess() {
      this.$router.push('/detailFacture')
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
