<template>
  <div class="text-left">
    <div
      class="flex tete p-6 text-c5 text-c14"
      @click="activePosition = -1"
    >
      <div class="w-1/2">
        Articles
      </div>

      <div class="w-1/2 flex">
        <div class="w-1/5 ml-4">
          Qté
        </div>
        <div class="w-1/3 ml-4">
          Prix unitaire
        </div>
        <div class="w-1/3 ml-4">
          Prix total
        </div>
      </div>
    </div>

    <div class="">
      <div
        class="w-full flex p-6"
        v-for="(item, index) in sendArticle"
        :key="index"
      >
        <div class="w-1/2">
          <div class="text-c14 font-c5">
            {{ item.marque }} {{ item.reference }}
          </div>
        </div>

        <div class="w-1/2 flex">
          <div class="w-1/5 ml-4">
            {{ item.qte }}
          </div>

          <div class="w-1/3 ml-4">
            {{ item.price }}
          </div>

          <div class="w-1/3 ml-4">
            {{ item.price * item.qte }}$
          </div>
        </div>
      </div>
    </div>

    <div class="h-1 bg-d w-full mt-10" />

    <div class="flex justify-center mt-6">
      <div class="w-1/5">
        <bouton
          label="Renseigner un article"
          size="14px"
          weight="600"
          color="#fff"
          background="#5138EE"
          radius="65.679px"
          height="37.07px"
          @oga="retourAdd"
        />
      </div>
    </div>

    <div class="h-1 bg-d w-full mt-6" />

    

    <div
      class="mt-6"
      v-if="sendArticle.length > 0"
    >
      <div class="flex justify-end">
        <div class="w-1/4">
          <div class="text-c12 mt-6">
            Frais de transport
          </div>

          <inputo
            border="1px solid #DCDCE4"
            class="mt-2"
            :value-t="0"
            type="number"
            @info="reference"
          />
        </div> 
      </div> 

      <div class="flex justify-end mt-6">
        <div class="w-1/3 flex items-center justify-end">
          <div class="text-c12 w-2/5">
            Quantité total :
          </div>

          <div class="w-2/5 h-10 bg-d rounded-5 text-right ml-4 p-2 pr-3">
            628
          </div>
        </div> 
      </div> 

      <div class="flex justify-end mt-6">
        <div class="w-1/3 flex items-center justify-end">
          <div class="text-c12 w-2/5">
            Total commande :
          </div>

          <div class="w-2/5 h-10 bg-d rounded-5 text-right ml-4 p-2 pr-3">
            628 000 $
          </div>
        </div> 
      </div> 
        
      <div class="h-1 bg-oho-blue w-full mt-10" />

      <upload
        label="Ajouter une pièce jointe"
        border="0.5px dashed #5138EE"
        size="14px"
        color="black"
        background="#EFF4FF"
        :icon="icons.document"
        class="mt-10"
      />

      <textare
        class="mt-10"
        radius="5px"
        placeholder="Laisser une note..."
      />
    </div>
  

    <diviser
      height="1.8px"
      color="#5138EE"
      class="mt-10"
    />
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import document from '../../../assets/icons/document.svg'
import upload from '../../helper/form/uploadTemplate.vue'
import textare from '../../helper/form/textarea.vue'
import inputo from '../../helper/form/input.vue'

export default {
  name: "Index",

  components: {
    bouton,
    upload,
    textare,
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    },

    allArticle: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        document
      },
      devis: null,
      activePosition: -1
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendArticle: function () {
      return this.allArticle
    }
  },

  created () {
  },

  methods: {
    retourAdd () {
      this.$emit('add', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
.tete{
  color: #434343;
  background: linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%);
  border-radius: 10px;
  border-top: 5px solid #434343;
}

</style>
